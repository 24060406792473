import {useState, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AxiosResponse} from 'axios';
// hooks
import useApi from './useApi';
import useUI from './useUI';
// actions
import {
  addConsultantServiceAction
} from 'store/grants/otherDirectCosts/ConsultantServices/actions';
// types
import {
  ConsultantServiceFormType,
} from 'store/grants/otherDirectCosts/ConsultantServices/types';
// components
import * as T from './requestTypes';
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";
import {checkIsCompleted} from "../helpers/utils";

type UseConsultantServicesReturnType = {
  isLoading: boolean;
  createService: (grantId: string) => void;
  updateService: (grantId: string, serviceId: string, data: ConsultantServiceFormType) => void;
  previewServiceData: (
    grantId: string,
    data: any,
    cb: (data: T.ConsultantServiceProviderPreviewResponse) => void
  ) => void;
  deleteService: (grantId: string, serviceId: string, redirect: boolean) => void;
  totals: string[][];
  updateProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string, data: any) => void;
  deleteProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string) => void;
  getTotals: (grantId: string) => void;
  addProvider: (grantId: string, serviceId: string, serialNumber: string | number, cb: (data: any) => void) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
};

type Props = {
  form_page_type?: string
}

const useConsultantServices = (props: Props = {}): UseConsultantServicesReturnType => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'consultant services';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved, formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const api = useApi();
  const {loader, onShowNavigationMessage} = useUI();
  const {getOneWithSide} = useGrants();
  const history = useHistory();
  const dispatch = useDispatch();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      'other direct costs',
      form_subcategory), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState<string[][]>([]);

  const createService = useCallback(
    (grantId: string) => {
      const emptyService = {
        name: null
      };
      setIsLoading(true);
      api.addConsultantServiceItem(grantId, emptyService)
        .then((response: AxiosResponse) => {
          setIsLoading(false);
          history.push(`/grants/${grantId}/otherDC/consultantServices/${response.data.id}/edit`);
          setTimeout(() => {
            dispatch(addConsultantServiceAction(response.data));
          }, 300);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [api, history, dispatch],
  );

  const updateService = useCallback((grantId: string, serviceId: string, data: ConsultantServiceFormType) => {
      setIsLoading(true);

      api.updateConsultantServiceItem(grantId, serviceId, data)
        .then(() => {
          formSuccessSaveCallBack()
          onShowNavigationMessage();
          setIsLoading(false);
          toast.success({
            title: 'The service details have been updated',
            message: 'Changes have been successfully saved',
          });
          getOneWithSide(grantId);
        })
        .catch(() => {
          setIsLoading(false);
        });

      trackFormSaveAttempted()
    },
    [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide],
  );

  const deleteService = useCallback((grantId: string, serviceId: string, redirect: boolean) => {
    confirm({
      title: 'Delete service info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteConsultantServiceItem(grantId, serviceId).then(() => {
          getOneWithSide(grantId, () => {
            if (redirect) history.push(`/grants/${grantId}/edit`);
            toast.success({
              title: 'The service has been deleted',
              message: 'Changes have been successfully saved',
            });
          });
        });
      },
    });
  }, [getOneWithSide, api, history]);

  const previewServiceData = useCallback(
    (grantId: string, data: any, cb: (data: any) => void) => {
      loader.start();
      api.previewConsultantServiceData(grantId, data)
        .then((response: AxiosResponse) => {
          loader.stop();
          cb(response.data);
        })
        .catch(() => {
          loader.stop();
        });
    }, [api, loader]);

  const getTotals = useCallback((grantId: string) => {
    setIsLoading(true);
    api.getConsultantTotals(grantId)
      .then((response: AxiosResponse) => {
        setTotals(response.data)
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api]);

  const addProvider = useCallback((grantId: string, serviceId: string, serialNumber: string | number, cb: (data: any) => void) => {
    setIsLoading(true);
    api.addConsultantServiceProvider(grantId, serviceId, serialNumber, {
      name: ''
    })
      .then((response: AxiosResponse) => {
        setIsLoading(false);
        getOneWithSide(grantId, () => cb(response.data));
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api, getOneWithSide]);

  const updateProvider = useCallback((grantId: string, serviceId: string, serialNumber: string | number, providerId: string, data: any) => {
    setIsLoading(true);
    api.updateConsultantServiceProvider(grantId, serviceId, serialNumber, providerId, data)
      .then(() => {
        setIsLoading(false);
        getOneWithSide(grantId);
        toast.success({
          title: 'The consultant service information has been updated ',
          message: 'Changes have been successfully saved',
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api, getOneWithSide]);

  const deleteProvider = useCallback((grantId: string, serviceId: string, serialNumber: string | number, providerId: string) => {
    confirm({
      title: 'Delete service provider info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteConsultantServiceProvider(grantId, serviceId, serialNumber, providerId)
          .then(() => {
            getOneWithSide(grantId, () => {
              toast.success({
                title: 'The service provider has been deleted',
                message: 'Changes have been successfully saved',
              });
            });
          })
          .catch(() => {

          });
      }
    })
  }, [api, getOneWithSide]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    isLoading,
    createService,
    addProvider,
    updateService,
    updateProvider,
    previewServiceData,
    deleteService,
    deleteProvider,
    totals,
    getTotals,
    trackError,
    trackExit,
    trackFormStarted
  };
};

export default useConsultantServices;
