import {useCallback, useState} from 'react';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import useApi from './useApi';
import useUI from './useUI';
import toast from 'components/toast';
import mtdcAdapter, {TableData} from './common/mtdcAdapter';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";
import {checkIsCompleted} from "../helpers/utils";

type iUseMtdc = {
  loading: boolean;
  submitLoading: boolean;
  tableData: TableData;
  preview: (grantId: string, data: any) => void;
  update: (grantId: string, data: any) => void;
  getMTDC: (grantId: string, cb?: () => void) => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useMtdc = (props: Props = {}): iUseMtdc => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'MTDC'
  const form_subcategory = null;
  const {formStarted, formSaveAttempted, formSaved,formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      form_category,
      form_subcategory,), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const {getOneWithSide} = useGrants();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [submitLoading, onChangeSubmitLoading] = useState<boolean>(false);
  const [tableData, onChangeTableData] = useState<TableData>(mtdcAdapter());
  const {loader, onShowNavigationMessage} = useUI();

  const getMTDC = useCallback((grantId: string, cb?: () => void) => {
    onChangeLoading(true);
    api.getGrantMTDC(grantId)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        const correctData = mtdcAdapter(response.data);
        onChangeTableData(correctData);
        if (cb) cb();
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api]);

  const preview = useCallback((grantId: string, data: any) => {
    loader.start();
    api.previewGrantMTDC(grantId, data)
      .then((response: AxiosResponse) => {
        loader.stop();
        const correctData = mtdcAdapter(response.data);
        onChangeTableData(correctData);
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const update = useCallback((grantId: string, data: any) => {
    onChangeSubmitLoading(true);
    api.updateGrantMTDC(grantId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeSubmitLoading(false);
        getOneWithSide(grantId);
        const correctData = mtdcAdapter(response.data);
        onShowNavigationMessage();
        onChangeTableData(correctData);
        toast.success({
          title: 'The MTDC have been updated',
          message: 'Changes have been successfully saved',
        });
      })
      .catch(() => {
        onChangeSubmitLoading(false);
      });
    trackFormSaveAttempted()
  }, [api, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage, trackFormSaveAttempted]);

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    preview,
    submitLoading,
    getMTDC,
    update,
    tableData,
    loading,
    trackFormStarted
  };
}

export default useMtdc;
