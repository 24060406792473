export type ListItem = {
  value: any;
  label: string;
}

export type HardListItem = {
  value: any;
  label: string;
  sub: ListItem[]
}

const completedFormRequiredField = {
  seniorPersonal: ['monthlyCompensationBasedOnSalary', 'monthsAvailableAfterRequest'],
  academicResearchAssociate: ['monthlyCompensationBasedOnSalary', 'monthsAvailableAfterRequest'],
  postDocPersonnel: ['percent'],
  civilService: ['amountOfPeople'],
  unionPersonnel: ['amountOfPeople'],
  tempAndCasual: ['amountOfPeople'],
  otherPersonnel: ['amountOfPeople'],
  equipment: ['numberOfUnits']
}

const keys = {
  "senior-personal": "seniorPersonal",
  "academic-research-personal": "academicResearchAssociate",
  "post-doc": "postDocPersonnel",
  "graduate-students": "graduateStudents",
  "civil-service": "civilService",
  "union": "unionPersonnel",
  "temp-n-casual": "tempAndCasual",
  "other-personnel": "otherPersonnel",
  "equipment": "equipment"
}

export const objectToSelectArray = (data: Record<string, string>, clearableLabel?: string): ListItem[] => {
  const list = Object.keys(data).reduce((result: ListItem[], item: string) => ([
    ...result,
    {
      value: data[item],
      label: data[item]
    }
  ]), []);
  if (clearableLabel) {
    return [
      {value: '', label: clearableLabel},
      ...list
    ];
  }
  return list;
};

export const toCamelCase = (str: string): string => {
  if (!str) return str;

  return str
    .toLowerCase()
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
}

// @ts-ignore
function hasRequiredFields(obj, requiredFields) {
  // @ts-ignore
  return requiredFields.every(field => obj.hasOwnProperty(field));
}

// @ts-ignore
export const checkIsCompleted = (callback, params, grant) => {
  // @ts-ignore
  const keyValue = keys[params.personnelType]
  // @ts-ignore
  const currentValue = grant?.[keyValue]?.length ? grant?.[keyValue].find(i => i.id === params.personId) : grant?.[keyValue]
  if (currentValue && currentValue.nsfCompensation) {
    if (currentValue.nsfCompensation.length === 0) {
      callback()
      return
    } else {
      // @ts-ignore
      let isCompleted = false
      // @ts-ignore
      if (completedFormRequiredField[keyValue]) {
        // @ts-ignore
        isCompleted = currentValue.nsfCompensation.every(i => hasRequiredFields(i, completedFormRequiredField[keyValue]));
      }
      if (isCompleted) {
        callback()
      }
    }
  } else {
    callback()
    return
  }
}
