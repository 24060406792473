import {useState, useCallback} from 'react';
import {AxiosError, AxiosResponse} from 'axios';
import {useDispatch} from 'react-redux';
import useApi from './useApi';
import useUI from './useUI';
import {useHistory, useParams} from 'react-router-dom';
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import parseErrors, {Errors} from 'helpers/errors';
import * as T from './requestTypes';
import {
  setOTDCS,
  addServiceToOTDCS
} from 'store/grants/otherDirectCosts/ComputerService/actions';
import useGrants from './useGrants';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";
import {checkIsCompleted} from "../helpers/utils";

type iUseCC = {
  createInfo: (grantId: string, data: any) => void;
  updateInfo: (grantId: string, data: any) => void;
  createService: (grantId: string) => void;
  getTotals: (grantId: string) => void;
  updateService: (grantId: string, id: string, data: any) => void;
  loading: boolean;
  deleteService: (grantId: string, id: string, redirect: boolean) => void;
  previewServiceYear: (grantId: string, id: string, data: T.ODCCSServiceYearPreviewData, cb: (data: any) => void) => void;
  errors: Errors;
  totals: string[][];
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useCS = (props: Props = {}): iUseCC => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'computer services';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved, formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      'other direct costs',
      form_subcategory,), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const history = useHistory();
  const {loader, onShowNavigationMessage} = useUI();
  const dispatch = useDispatch();
  const {getOneWithSide} = useGrants();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const [totals, onChangeTotals] = useState<string[][]>([]);

  const createInfo = useCallback((grantId: string, data: T.ODCCSInfo) => {
    onChangeLoading(true);
    api.addODCComputerService(grantId, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        dispatch(setOTDCS(response.data));
        toast.success({
          title: 'The computer services information has been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, dispatch]);

  const updateInfo = useCallback((grantId: string, data: T.ODCCSInfo) => {
    onChangeLoading(true);
    api.updateODCComputerService(grantId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        dispatch(setOTDCS(response.data));
        toast.success({
          title: 'The computer services information has been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, dispatch, onShowNavigationMessage]);

  const updateService = useCallback((grantId: string, id: string, data: T.ODCCSServiceData) => {
    onChangeLoading(true);
    api.updateServiceODCCS(grantId, id, data)
      .then(() => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'The computer service details have been updated',
          message: 'Changes have been successfully saved'
        });
        getOneWithSide(grantId);
      })
      .catch(() => {
        onChangeLoading(false);
      })

    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const deleteService = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete event info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteServiceODCCS(grantId, id)
          .then(() => {
            getOneWithSide(grantId, () => {
              toast.success({
                title: 'The computer service has been deleted',
                message: 'Changes have been successfully saved'
              });
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const createService = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.addServiceODCCS(grantId)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/otherDC/computer-services/${response.data.id}/edit`)
        setTimeout(() => {
          dispatch(addServiceToOTDCS(response.data));
        }, 300);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api, dispatch, history]);

  const previewServiceYear = useCallback((grantId: string, id: string, data: T.ODCCSServiceYearPreviewData, cb: (data: any) => void) => {
    loader.start();
    //@ts-ignore
    const q = new URLSearchParams(data).toString();
    api.previewServiceODCCS(grantId, id, `?${q}`)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data);
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.ODCComputerServiceTotals(grantId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data)
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api])
  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    createInfo,
    totals,
    errors,
    deleteService,
    previewServiceYear,
    getTotals,
    createService,
    updateInfo,
    updateService,
    loading,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useCS;
