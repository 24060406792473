import useApi from './useApi';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import * as T from './requestTypes';
import {
  addTravelToGrant,
  updateTravelStatusAction
} from 'store/grants/actions';
import {TravelCostsTotals} from 'store/grants/types';
import {costAdapter, travelCostsTotalsAdapter} from 'store/grants/adapters';
import {useCallback, useState} from 'react';
import parseErrors, {Errors} from 'helpers/errors';
import {AxiosError, AxiosResponse} from 'axios';
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import {checkIsCompleted, ListItem} from 'helpers/utils';
import useUI from './useUI';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";

export type Total = {
  method: string;
  name: string;
  fullName: string;
  years: ListItem[];
  total: number;
  totals: number;
}

export type Totals = Record<string, Total[]>;
export type TableTotals = Record<string, any[]>;
export type TransportationTotals = {
  domestic: any[];
  foreign: any[];
  domesticAndForeign: any[];
}

export type AllTotal = {
  id: string;
  title: string;
  data: string[][];
}

type iUseTravel = {
  costTotals: TravelCostsTotals;
  create: (grantId: string, data: T.EmptyTravelData) => void;
  update: (grantId: string, travelId: string, data: T.CreateTravelData) => void;
  updateStatus: (grantId: string, travelId: string, data: T.StatusTravelData) => void;
  destroy: (grantId: string, travelId: string, redirect?: boolean) => void;
  removeTransportationMethod: (grantId: string, travelId: string, methodId: string, redirect: boolean) => void;
  loading: boolean;
  addTransportationMethod: (grantId: string, travelId: string, type: string, method: string, data: T.TransportationMethodData) => void;
  updateTransportationMethod: (grantId: string, travelId: string, methodId: string, data: T.TransportationMethodData) => void;
  errors: Errors;
  totals: Totals;
  travelTotals: Totals;
  transportationTotals: TransportationTotals;
  getTransportationMethodsTotals: (grantId: string, tripId: string) => void;
  clearErrors: () => void;
  duplicateTravel: (grantId: string, travelId: string) => void;
  onChangeErrors: (errors: Errors) => void;
  previewMethod: (grantId: string, travelId: string, data: T.TransportationMethodData, cb: (response: T.TransportationMethodData) => void) => void;
  addTravelCost: (grantId: string, travelId: string, type: string, kind: string, data: T.TravelCostData) => void;
  getTravelCost: (grantId: string, travelId: string, costId: string) => void;
  updateTravelCost: (grantId: string, travelId: string, costId: string, data: any) => void;
  updateTravelCostMaIE: (grantId: string, travelId: string, costId: string, data: any, tab: string) => void;
  removeTravelCost: (grantId: string, travelId: string, costId: string, redirect: boolean) => void;
  previewTravelCost: (grantId: string, travelId: string, costId: string, data: any, cb: (data: T.TravelCostData) => void, pure?: boolean) => void;
  previewOtherTravelCost: (grantId: string, travelId: string, costId: string, data: any, cb: (data: T.TravelCostData) => void) => void;
  getTravelTotals: (grantId: string, id: string) => void;
  getTotalsForAllTrips: (grantId: string) => void;
  allTotals: AllTotal[];
  getTravelCostTotals: (grantId: string, id: string) => void;
  getTravelMethodsTotals: (grantId: string, id: string) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useTravel = (props: Props = {}): iUseTravel => {
  const form_page_type = props.form_page_type || '';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved, formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const api = useApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const {loader, onShowNavigationMessage} = useUI();
  const {getOneWithSide} = useGrants();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const [totals, onChangeTotals] = useState<TableTotals>({});
  const [allTotals, onChangeAllTotals] = useState<AllTotal[]>([]);
  const [travelTotals, onChangeTravelTotals] = useState<TableTotals>({
    domestic: [],
    domesticAndForeign: [],
    foreign: [],
  });
  const [transportationTotals, onChangeTransportationTotals] = useState<TransportationTotals>({
    domestic: [],
    domesticAndForeign: [],
    foreign: [],
  });
  const [costTotals, onChangeCostTotals] = useState<TravelCostsTotals>(travelCostsTotalsAdapter({} as TravelCostsTotals))
  const pageNumber = params.year ? Number(params.year) + 2 : 1;


  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'travel',
      params.type,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      'travel',
      params.type), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'travel',
      params.type,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id, params.type])

  const create = useCallback((grantId: string, data: T.EmptyTravelData) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyData = {
      isConferenceTrip: null,
      tripDefaultLocation: null,
      tripDescription: null,
      tripName: null,
      tripPurpose: null,
      ...data
    };
    api.createTravel(grantId, emptyData)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        dispatch(addTravelToGrant(response.data));
        history.push(`/grants/${grantId}/travel/${response.data.type}/${response.data.id}/edit`);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, history, dispatch]);

  const update = useCallback((grantId: string, travelId: string, data: T.CreateTravelData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateTravel(grantId, travelId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        getOneWithSide(grantId);
        onShowNavigationMessage();
        toast.success({
          title: 'The trip details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const updateStatus = useCallback((grantId: string, travelId: string, data: T.StatusTravelData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateTravel(grantId, travelId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        dispatch(updateTravelStatusAction({travelId, data}));
        onChangeLoading(false);
        onShowNavigationMessage();
        getOneWithSide(grantId);
        toast.success({
          title: 'Trip status has been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, dispatch, onShowNavigationMessage, getOneWithSide]);

  const destroy = useCallback((grantId: string, travelId: string, redirect?: boolean) => {
    confirm({
      title: 'Delete travel info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.removeTravel(grantId, travelId)
          .then(() => {
            getOneWithSide(grantId);
            if (redirect) history.push(`/grants/${grantId}/edit`);
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const addTransportationMethod = useCallback((grantId: string, travelId: string, type: string, method: string, data: T.TransportationMethodData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.addTransportationMethod(grantId, travelId, method, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        getOneWithSide(grantId);
        toast.success({
          title: 'Transportation method has beed added',
          message: 'Changes have been successfully saved'
        });
        history.push(`/grants/${grantId}/travel/${type}/${travelId}/transportation/${response.data.id}/edit`);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, getOneWithSide, history]);

  const updateTransportationMethod = useCallback((grantId: string, travelId: string, methodId: string, data: T.TransportationMethodData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateTransportationMethod(grantId, travelId, methodId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        toast.success({
          title: 'Transportation method details have been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
        getOneWithSide(grantId);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const removeTransportationMethod = useCallback((grantId: string, travelId: string, methodId: string, redirect: boolean) => {
    confirm({
      title: 'Delete transportation method',
      text: 'Are you sure you want to delete this transportation method? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.removeTransportationMethod(grantId, travelId, methodId)
          .then(() => {
            getOneWithSide(grantId);
            if (redirect) history.push(`/grants/${grantId}/edit`)
          });
      }
    })
  }, [api, getOneWithSide, history]);

  const previewMethod = useCallback((grantId: string, travelId: string, data: T.TransportationMethodData, cb: (response: T.TransportationMethodData) => void) => {
    api.previewTransportationMethod(grantId, travelId, data)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api]);

  const dataToTotals = (data: Total[]): Total[] => {
    return (data ?? []).map((item: Total) => ({
      method: item?.method ?? '',
      name: item?.name ?? '',
      fullName: item?.fullName ?? '',
      years: item?.years ?? [],
      total: item?.total ?? '',
      totals: item?.totals ?? '',
    }))
  }

  const getTransportationMethodsTotals = useCallback((grantId: string, tripId: string) => {
    onChangeLoading(true);
    api.getTransportationMethodTotals(grantId, tripId)
      .then((response: AxiosResponse) => {
        onChangeTransportationTotals({
          domestic: response.data.domestic ?? [],
          domesticAndForeign: response.data.domesticAndForeign ?? [],
          foreign: response.data.foreign ?? [],
        });
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api]);

  const addTravelCost = useCallback((grantId: string, travelId: string, type: string, kind: string, data: T.TravelCostData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.addTravelCost(grantId, travelId, kind, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        getOneWithSide(grantId, () => {
          history.push(`/grants/${grantId}/travel/${type}/${travelId}/cost/${response.data.id}/edit`)
          toast.success({
            title: 'Travel costs have been added',
            message: 'Changes have been successfully saved'
          });
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, history, dispatch]);

  const previewTravelCost = useCallback((grantId: string, travelId: string, costId: string, data: any, cb: (data: T.TravelCostData) => void, pure?: boolean) => {
    loader.start();
    api.previewTravelCost(grantId, travelId, costId, data)
      .then((response: AxiosResponse) => {
        //@ts-ignore
        cb(pure ? response.data : costAdapter(response.data));
        loader.stop();
      })
      .catch((error: AxiosError) => {
        loader.stop();
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, loader]);

  const previewOtherTravelCost = useCallback((grantId: string, travelId: string, costId: string, data: any, cb: (data: T.TravelCostData) => void) => {
    loader.start();
    onChangeErrors({});
    api.previewOnCreateTravelCost(grantId, travelId, costId, data)
      .then((response: AxiosResponse) => {
        //@ts-ignore
        cb(costAdapter(response.data));
        loader.stop();
      })
      .catch((error: AxiosError) => {
        loader.stop();
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
  }, [api, loader]);

  const updateTravelCost = useCallback((grantId: string, travelId: string, costId: string, data: any) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateTravelCost(grantId, travelId, costId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        getOneWithSide(grantId);
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Travel costs details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const updateTravelCostMaIE = useCallback((grantId: string, travelId: string, costId: string, data: T.TravelCostData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateTravelCost(grantId, travelId, costId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        getOneWithSide(grantId);
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Travel costs details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const removeTravelCost = useCallback((grantId: string, travelId: string, costId: string, redirect: boolean) => {
    confirm({
      title: 'Delete travel cost',
      text: 'Are you sure you want to delete this travel cost? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.removeTravelCost(grantId, travelId, costId)
          .then(() => {
            getOneWithSide(grantId);
            if (redirect) history.push(`/grants/${grantId}/edit`)
          });
      }
    })
  }, [api, getOneWithSide, history]);

  const getTravelCost = useCallback((grantId: string, travelId: string, costId: string) => {
    onChangeLoading(true);
    api.getTravelCost(grantId, travelId, costId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data.totals ?? {});
        onChangeLoading(false);
      })
      .catch(() => onChangeLoading(false));
  }, [api]);

  const duplicateTravel = useCallback((grantId: string, travelId: string) => {
    confirm({
      title: 'Duplicate travel',
      text: 'Are you sure you want to duplicate this travel?',
      type: 'success',
      icon: 'copy-01',
      okText: 'Duplicate',
      onConfirm: () => {
        api.duplicateTravel(grantId, travelId)
          .then((response: AxiosResponse) => {
            getOneWithSide(grantId);
            history.push(`/grants/${grantId}/travel/${response.data.type}/${response.data.id}/edit`);
          });
      }
    });
  }, [api, history, getOneWithSide]);

  const getTravelTotals = useCallback((grantId: string, id: string) => {
    onChangeLoading(true);
    api.getTravelTotals(grantId, id)
      .then((response: AxiosResponse) => {
        onChangeTravelTotals(travelCostsTotalsAdapter(response.data));
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      })
  }, [api]);

  const getTravelCostTotals = useCallback((grantId: string, id: string) => {
    onChangeLoading(true);
    api.getTravelCostTotals(grantId, id)
      .then((response: AxiosResponse) => {
        onChangeCostTotals(travelCostsTotalsAdapter(response.data ?? {}));
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api]);

  const getTravelMethodsTotals = useCallback((grantId: string, id: string) => {
    api.getTravelMethodsTotals(grantId, id);
  }, [api]);

  const getTotalsForAllTrips = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.getTotalsForAllTrips(grantId)
      .then((response: AxiosResponse) => {
        onChangeAllTotals(response.data);
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api]);

  const clearErrors = useCallback(() => onChangeErrors({}), []);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'travel',
      params.type,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id, params.type])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'travel',
      params.type,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id, params.type])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'travel',
      params.type,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id, params.type])

  return {
    create,
    addTravelCost,
    totals,
    getTotalsForAllTrips,
    transportationTotals,
    getTravelCostTotals,
    costTotals,
    getTravelMethodsTotals,
    getTravelTotals,
    updateStatus,
    getTravelCost,
    duplicateTravel,
    getTransportationMethodsTotals,
    destroy,
    previewMethod,
    onChangeErrors,
    travelTotals,
    removeTravelCost,
    previewTravelCost,
    previewOtherTravelCost,
    updateTransportationMethod,
    clearErrors,
    updateTravelCost,
    allTotals,
    updateTravelCostMaIE,
    removeTransportationMethod,
    update,
    addTransportationMethod,
    loading,
    errors,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useTravel;
