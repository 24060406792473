import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useApi from './useApi';
import useUI from './useUI';
import toast from 'components/toast';
import { confirm } from 'components/confirmation';
import { Errors } from 'helpers/errors';
import * as T from './requestTypes';
import { addPaymentToODC } from 'store/grants/otherDirectCosts/Payments/actions';
import useGrants from './useGrants';
import { FieldErrors } from "react-hook-form";
import { useMixPanel } from "./useMixPanel";
import {checkIsCompleted} from "../helpers/utils";

type iUseIncentivePayments = {
  createPayment: (grantId: string) => void;
  getTotals: (grantId: string) => void;
  updatePayment: (grantId: string, id: string, data: T.ODCPaymentUpdate) => void;
  loading: boolean;
  deletePayment: (grantId: string, id: string, redirect: boolean) => void;
  previewPaymentYear: (grantId: string, id: string, data: any, cb: (data: any) => void) => void;
  errors: Errors;
  totals: string[][];
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}
type Props = {
  form_page_type?: string
}
const useIncentivePayments = (props: Props = {}): iUseIncentivePayments => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'incentive payments';
  const { formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved,formCompleted } = useMixPanel();
  const { grant } = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      'other direct costs',
      form_subcategory,), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const history = useHistory();
  const { loader, onShowNavigationMessage } = useUI();
  const dispatch = useDispatch();
  const { getOneWithSide } = useGrants();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const [totals, onChangeTotals] = useState<string[][]>([]);

  const updatePayment = useCallback((grantId: string, id: string, data: T.ODCPaymentUpdate) => {
    onChangeLoading(true);
    api.updatePaymentODC(grantId, id, data)
      .then(() => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        toast.success({
          title: 'The incentive payment details have been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
        getOneWithSide(grantId);
      })
      .catch(() => {
        onChangeLoading(false);
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const deletePayment = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete incentive payment info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deletePaymentODC(grantId, id)
          .then(() => {
            getOneWithSide(grantId, () => {
              toast.success({
                title: 'The payment has been deleted',
                message: 'Changes have been successfully saved'
              });
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const createPayment = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.createODCPayment(grantId, {
      name: null,
      amount: null,
      isInflationAppliedEachYear: false
    })
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/otherDC/payments/${response.data.id}/edit`)
        setTimeout(() => {
          dispatch(addPaymentToODC(response.data));
        }, 300);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api, dispatch, history]);

  const previewPaymentYear = useCallback((grantId: string, id: string, data: Record<string, string>, cb: (data: T.ODCPaymentUpdate) => void) => {
    loader.start();
    const q = new URLSearchParams(data).toString();
    api.previewYearPaymentODC(grantId, id, `?${q}`)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data);
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.paymentODCTotals(grantId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data)
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api])

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    totals,
    errors,
    deletePayment,
    previewPaymentYear,
    getTotals,
    createPayment,
    updatePayment,
    loading,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useIncentivePayments;
