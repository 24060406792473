import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ContextMenuProvider } from "context/useContextMenu";
import GoogleAuthProvider from "containers/oauth/google";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";
import ContextMenu from "containers/contextMenu";
import ScrollToTop from "containers/scrollToTop";
import Chatbot from "containers/chatbot";
import dayjs from "dayjs";
import Routes from "./routes";
import store from "./store";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import FrillWidget from "containers/frill";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

dayjs.locale("en-US");
registerLocale("en-US", enUS);
setDefaultLocale("en-US");

console.log(process.env);

if (process.env.REACT_APP_HOTJAR_SITE_ID) {
  Hotjar.init(Number(process.env.REACT_APP_HOTJAR_SITE_ID), 6);
}
mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
  ignore_dnt: true,
  persistence: "localStorage",
});

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter getUserConfirmation={() => {}}>
        <Provider store={store}>
          <GoogleAuthProvider>
            <ContextMenuProvider>
              <ScrollToTop />
              <Routes />
              <ToastContainer draggable={false} hideProgressBar />
              <ContextMenu />
              <Chatbot />
              <FrillWidget />
            </ContextMenuProvider>
          </GoogleAuthProvider>
        </Provider>
      </BrowserRouter>
    </DndProvider>
  );
};

export default App;
