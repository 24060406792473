import {useState, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import useApi from './useApi';
import useUI from './useUI';
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import {Errors} from 'helpers/errors';
import * as T from './requestTypes';
import useGrants from './useGrants';
import {addContract} from 'store/grants/otherDirectCosts/Contracts/actions';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";
import {checkIsCompleted} from "../helpers/utils";

type iUseContracts = {
  createContract: (grantId: string) => void;
  getTotals: (grantId: string) => void;
  updateContract: (grantId: string, id: string, data: any) => void;
  loading: boolean;
  deleteContract: (grantId: string, id: string, redirect: boolean) => void;
  previewContractYear: (grantId: string, id: string, data: any, cb: (data: any) => void) => void;
  errors: Errors;
  totals: string[][];
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useContracts = (props: Props = {}): iUseContracts => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'contacts';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved, formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      'other direct costs',
      form_subcategory), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const history = useHistory();
  const {loader, onShowNavigationMessage} = useUI();
  const {getOneWithSide} = useGrants();
  const dispatch = useDispatch();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const [totals, onChangeTotals] = useState<string[][]>([]);

  const updateContract = useCallback((grantId: string, id: string, data: T.ContractCreate) => {
    onChangeLoading(true);
    api.updateContract(grantId, id, data)
      .then(() => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'The contract details have been updated',
          message: 'Changes have been successfully saved'
        });
        getOneWithSide(grantId);
      })
      .catch(() => {
        onChangeLoading(false);
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const deleteContract = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete contract info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteContract(grantId, id)
          .then(() => {
            getOneWithSide(grantId, () => {
              toast.success({
                title: 'The contract has been deleted',
                message: 'Changes have been successfully saved'
              });
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const createContract = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.addContract(grantId, {
      name: null,
      isInflationAppliedEachYear: true
    })
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/otherDC/contracts/${response.data.id}/edit`)
        setTimeout(() => {
          dispatch(addContract(response.data));
        }, 300);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api, dispatch, history]);

  const previewContractYear = useCallback((grantId: string, id: string, data: any, cb: (data: any) => void) => {
    loader.start();
    const q = new URLSearchParams(data).toString();
    api.previewContractYear(grantId, id, `?${q}`)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data);
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.contractsTotals(grantId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data)
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api])

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    totals,
    errors,
    deleteContract,
    previewContractYear,
    getTotals,
    createContract,
    updateContract,
    loading,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useContracts;
