import mixpanel from 'mixpanel-browser';
import {useSelector} from "react-redux";
import {authUserSelector} from "../store/auth/selector";
import {useLocation} from "react-router";
import {reformatDateMixPanel, stringOrUndefined} from "../helpers/date";

type AccountCreationMethod = "email" | "google" | "microsoft";

export const useMixPanel = () => {
  const location = useLocation();
  const user = useSelector(authUserSelector);

  const eventsList = {
    pageViewed: (pageName: string) => {
      console.log("Page Viewed", {
        page_name: pageName,
        organization_id: user.organization.id
      })
      mixpanel.track("Page Viewed", {
        page_name: pageName,
        organization_id: user.organization.id
      })
    },
    accountCreated: (method: AccountCreationMethod) => {
      console.log("Account Created", {
        method: method,
        organization_id: user.organization.id
      })
      mixpanel.track("Account Created", {
        method: method,
        organization_id: user.organization.id
      })
    },
    emailVerified: () => {
      console.log("Email Verified", {
        organization_id: user.organization.id,
      })

      mixpanel.track("Email Verified", {
        organization_id: user.organization.id,
      })
    },
    grantBudgetCreated: (grant_id: string) => {
      console.log("Grant Budget Created", {
        grant_id: grant_id,
        organization_id: user.organization.id,
      })

      mixpanel.track("Grant Budget Created", {
        grant_id: grant_id,
        organization_id: user.organization.id,
      })
    },
    formStarted: (grant_id: string, form_category: string, form_subcategory: string | null, form_page_type: string, page_number: number) => {
      console.log("Form Started", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
      mixpanel.track("Form Started", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
    },
    formSaveAttempted: (grant_id: string, form_category: string, form_subcategory: string | null, form_page_type: string, page_number: number) => {
      console.log("Form Save Attempted", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
      mixpanel.track("Form Save Attempted", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
    },
    formSaveFailed: (grant_id: string, form_category: string, form_subcategory: string | null, form_page_type: string, page_number: number, error_message: string) => {
      console.log("Form Save Failed", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        error_message: error_message,
        organization_id: user.organization.id
      })
      mixpanel.track("Form Save Failed", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        error_message: error_message,
        organization_id: user.organization.id
      })
    },
    formExited: (grant_id: string, form_category: string, form_subcategory: string | null, form_page_type: string, page_number: number) => {
      console.log("Form Exited", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
      mixpanel.track("Form Exited", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
    },
    formSaved: (grant_id: string, form_category: string, form_subcategory: string | null, form_page_type: string, page_number: number) => {
      console.log("Form Saved", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
      mixpanel.track("Form Saved", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        form_page_type: form_page_type,//Position Info, Date
        page_number: page_number,//1,2,3
        organization_id: user.organization.id
      })
    },
    formCompleted: (grant_id: string, form_category: string, form_subcategory: string | null) => {
      console.log("Form Completed", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        organization_id: user.organization.id
      })

      mixpanel.track("Form Completed", {
        grant_id: grant_id,
        form_category: form_category, //Personnel, Equipment, Travel
        form_subcategory: form_subcategory, //Senior Personnel, Grant Personnel Trips
        organization_id: user.organization.id
      })
    },
    grantBudgetExported: (grant_id: string, export_type: string, file_type: string) => {
      console.log("Grant Budget Exported", {
        grant_id: grant_id,
        export_type: export_type, //Form, Summary Proposal Budget
        file_type: file_type, //Excel, CSV, PDF
        organization_id: user.organization.id
      })
      mixpanel.track("Grant Budget Exported", {
        grant_id: grant_id,
        export_type: export_type, //Form, Summary Proposal Budget
        file_type: file_type, //Excel, CSV, PDF
        organization_id: user.organization.id
      })
    },
    grantBudgetDeleted: (grant_id: string) => {
      console.log("Grant Budget Deleted", {
        grant_id: grant_id,
        organization_id: user.organization.id
      })
      mixpanel.track("Grant Budget Deleted", {
        grant_id: grant_id,
        organization_id: user.organization.id
      })
    },
    grantBudgetStatusUpdated: (grant_id: string, status_origin: string, status_destination: string) => {
      console.log("Grant Budget Status Updated", {
        grant_id: grant_id,
        status_origin: status_origin, //In Progress, Pending Organization Approval
        status_destination: status_destination, //Pending NSF Approval, Accepted
        organization_id: user.organization.id
      })
      mixpanel.track("Grant Budget Status Updated", {
        grant_id: grant_id,
        status_origin: status_origin, //In Progress, Pending Organization Approval
        status_destination: status_destination, //Pending NSF Approval, Accepted
        organization_id: user.organization.id
      })
    },
    grantAcceptedFormSubmitted: (grant_id: string) => {
      console.log("Grant Accepted Form Submitted", {
        grant_id: grant_id,
        organization_id: user.organization.id
      })
      mixpanel.track("Grant Accepted Form Submitted", {
        grant_id: grant_id,
        organization_id: user.organization.id
      })
    },
    planUpgradeClicked: () => {
      console.log("Plan Upgrade Clicked", {
        organization_id: user.organization.id
      })
      mixpanel.track("Plan Upgrade Clicked", {
        organization_id: user.organization.id
      })
    },
    membershipStarted: (membership_id: string, membership_plan: string, amount: number, discount_code: string | null) => {
      console.log("Membership Started", {
        membership_id: membership_id,
        membership_plan: membership_plan, //Budget Mentor Billed to You, Budget Mentor Billed to Grant
        amount: amount,
        discount_code: discount_code,
        organization_id: user.organization.id
      })
      mixpanel.track("Membership Started", {
        membership_id: membership_id,
        membership_plan: membership_plan, //Budget Mentor Billed to You, Budget Mentor Billed to Grant
        amount: amount,
        discount_code: discount_code,
        organization_id: user.organization.id
      })
    },
    signedIn: (method: AccountCreationMethod) => {
      console.log("Signed In", {
        method: method,
        organization_id: user.organization.id
      })
      mixpanel.track("Signed In", {
        method: method,
        organization_id: user.organization.id
      })
    },
    signedOut: () => {
      console.log("Signed Out", {
        organization_id: user.organization.id
      })
      mixpanel.track("Signed Out", {
        organization_id: user.organization.id
      })
    },
    personalInformationEdited: () => {
      console.log("Personal Information Edited", {
        organization_id: user.organization.id
      })
      mixpanel.track("Personal Information Edited", {
        organization_id: user.organization.id
      })
    },
    personalInformationUpdated: () => {
      console.log("Personal Information Updated", {
        organization_id: user.organization.id
      })
      mixpanel.track("Personal Information Updated", {
        organization_id: user.organization.id
      })
    },
    organizationInformationEdited: () => {
      console.log("Organization Information Edited", {
        organization_id: user.organization.id
      })
      mixpanel.track("Organization Information Edited", {
        organization_id: user.organization.id
      })
    },
    organizationInformationSaved: () => {
      console.log("Organization Information Saved", {
        organization_id: user.organization.id
      })
      mixpanel.track("Organization Information Saved", {
        organization_id: user.organization.id
      })
    },
    showGuideClicked: () => {
      const page_name = location.pathname.includes('account') ? "account settings" : "dashboard"

      console.log("Show Guide Clicked", {
        page_name: page_name, //dashboard, account settings
      })

      mixpanel.track("Show Guide Clicked", {
        page_name: page_name, //dashboard, account settings
      })
    },

    hideGuideClicked: () => {
      console.log("Show Guide Hid", {})

      mixpanel.track("Show Guide Hid",)
    },


    setGrantGroupProperties: (grant_id: string, data: any) => {
      try {
        mixpanel.set_group('grant_id', grant_id);
        const group = mixpanel.get_group('grant_id', grant_id);

        const grantProperties = {
          'grant_status': data.step,//In progress, Accepted
          'grant_title': data.title,//Biomedical Research
          'grant_type': data.type,//NSF, DOD
          'organization_id': user.organization.id,//1234
          'organization_name': data?.organization?.name,//GAM
          'department': data?.organization?.department,//Economics
          'college': data?.organization?.subGroup,//Agricultural & Sciences
          'grant_start_date': reformatDateMixPanel(data.startDate),//2020-01-01
          'grant_end_date': reformatDateMixPanel(data.endDate),//2023-06-15
          'number_grant_years': data.datesCalculations?.years,//3
          'number_grant_months': data.datesCalculations?.months,//5
          'number_grant_days': data.datesCalculations?.days,//15
          'NSF_grant_name': data.nsf?.solicitationName,//
          'NSF_grant_number': data.nsf?.solicitationNumber,//123
          'NSF_directorate': data.derectoratesFullName,//STEM, Geoscience, Engineering
          'NSF_division': data.divisionsFullName,//
          'max_budget_cap': data.budgetCap,//300000
          'last_updated': reformatDateMixPanel(stringOrUndefined(new Date())),//2024-03-01
        };

        group.set(grantProperties);

        console.log("GrantGroupProperties", grantProperties)
      } catch (error) {
        console.error(error);
      }
    }
  }

  return {
    ...eventsList
  }
}

//track mix panel Email Verified event
