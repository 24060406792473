import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
// hooks
import useUI from 'hooks/useUI';
import useApi from 'hooks/useApi';
// types
import {CostSharingExpenseItemType} from 'store/grants/costSharing/types';
// actions
import {addCostSharingExpenseItemAction} from 'store/grants/costSharing/actions';
// components
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";
import {checkIsCompleted} from "../helpers/utils";

type UseCostSharingReturnType = {
  isLoading: boolean;
  createExpense: (grantId: string) => void;
  updateExpense: (grantId: string, expenseId: string, data: CostSharingExpenseItemType) => void;
  deleteExpense: (grantId: string, expenseId: string, redirect: boolean) => void;
  previewExpenseYear: (
    grantId: string,
    expenseId: string,
    data: any,
    cb: (data: any) => void
  ) => void;
  total: Record<string, string[][]>;
  getTotals: (grantId: string) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useCostSharing = (props: Props = {}): UseCostSharingReturnType => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'Cost sharing'
  const form_subcategory = null;
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved, formCompleted} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const pageNumber = params.year ? Number(params.year) + 2 : 1;

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
    checkIsCompleted(() => formCompleted(grant.id,
      form_category,
      form_subcategory), params, grant)
  }, [formSaved, grant, params, form_page_type, pageNumber, formCompleted])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formSaveAttempted, form_page_type, grant.id])

  const {
    addCostSharingItem,
    updateCostSharingItem,
    deleteCostSharingItem,
    previewCostSharingItemYear,
    getCostSharingTotals,
  } = useApi();
  const {loader, onShowNavigationMessage} = useUI();
  const {getOneWithSide} = useGrants();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<Record<string, string[][]>>({});

  const createExpense = useCallback((grantId: string) => {
    const emptyExpense = {name: null, isInflationApplied: true, type: null};
    setIsLoading(true);
    addCostSharingItem(grantId, emptyExpense)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        setIsLoading(false);
        history.push(`/grants/${grantId}/costSharing/${response.data.id}/edit`);
        setTimeout(() => {
          dispatch(addCostSharingExpenseItemAction(response.data));
        }, 300);
      })
      .catch(() => {
        setIsLoading(false);
      });
    trackFormSaveAttempted()
  }, [addCostSharingItem, trackFormSaveAttempted, formSuccessSaveCallBack, history, dispatch]);

  const updateExpense = useCallback((grantId: string, expenseId: string, data: CostSharingExpenseItemType) => {
    setIsLoading(true);
    updateCostSharingItem(grantId, expenseId, data)
      .then(() => {
        formSuccessSaveCallBack()
        setIsLoading(false);
        toast.success({
          title: 'The expense details have been updated',
          message: 'Changes have been successfully saved',
        });
        onShowNavigationMessage();
        getOneWithSide(grantId);
      })
      .catch(() => {
        setIsLoading(false);
      });
    trackFormSaveAttempted()
  }, [updateCostSharingItem, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const deleteExpense = useCallback((grantId: string, expenseId: string, redirect: boolean) => {
    confirm({
      title: 'Delete expense info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        deleteCostSharingItem(grantId, expenseId).then(() => {
          getOneWithSide(grantId, () => {
            toast.success({
              title: 'The expense has been deleted',
              message: 'Changes have been successfully saved',
            });
            if (redirect) history.push(`/grants/${grantId}/edit`);
          });
        });
      },
    });
  }, [deleteCostSharingItem, history, getOneWithSide]);

  const previewExpenseYear = useCallback(
    (grantId: string, expenseId: string, data: any, cb: (data: any) => void) => {
      loader.start();
      const q = new URLSearchParams(data).toString();
      previewCostSharingItemYear(grantId, expenseId, `?${q}`)
        .then((response: AxiosResponse) => {
          loader.stop();
          cb(response.data);
        })
        .catch(() => {
          loader.stop();
        });
    }, [previewCostSharingItemYear, loader]);

  const getTotals = useCallback((grantId: string) => {
    setIsLoading(true);
    getCostSharingTotals(grantId)
      .then((response: AxiosResponse) => {
        setTotal(response.data.totals)
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [getCostSharingTotals]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber,
      error_message
    )
  }, [pageNumber, formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      pageNumber
    )
  }, [pageNumber, formStarted, form_page_type, grant.id])

  return {
    isLoading,
    createExpense,
    updateExpense,
    deleteExpense,
    previewExpenseYear,
    total,
    getTotals,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useCostSharing;
